<template>
	<section>
		<div class="top">
			<div class="row justify-content-center">
				<div class="col-lg-9">
					<div class="text-center mb-5">
						<h1 class="top-title">产品和解决方案</h1>
					</div>
				</div>
			</div>
		</div>



		<div class="section container" v-if="index == 1">
			<div class="col-lg-12">
				<div class="text-center mb-5">
					<h2>【宇佑小仓】船舶智能舱室一体化解决方案</h2>
				</div>
				<div class="col-lg-12">
					<p>【宇佑小仓】船舶智能舱室一体化解决方案，是宇佑【海上生活类】方案的重要组成部分。</p>
					<p>以网络信息通讯技术为核心，整个系统由PMS系统、电视信号传输系统、智能邮轮客舱系统、RCU房控系统、 信发系统、无线网络覆盖设备、高速网络设备、无人入住系统等组成。
					</p>
					<p>智能电视终端、
						自助Checkin终端、电子门牌、手机、数字标牌和互动式语音操控机器人作为用户交互界面，以客舱智能显示终端为窗口，实现邮轮自助服务。信息发布、信息查询、灯光等设备控制、移动支付和电子发票等人性化服务，提升邮轮品味和服务品质、提高客人入住满意度、增强邮轮管理效率、降低运营成本、减少后期维护，开创增值服务平台，为客户打造全面的智能化邮轮、海上旅游、海上生活场景，满足了游客客户社交、旅游、健康、活动、娱乐需求。
					</p>
					<p>【宇佑小仓】目前已在多个【海上生活】场景实施，其兼具先进、智能、人性化和文化性，优秀的体验感和方便的交互长期获得业主使用者的称赞。</p>
				</div>
				<div class="mb-20 col-lg-12 row" style="justify-content: space-around;align-items: center;">
					<div class="col-lg-5 text-center ">
						<b-img class="image" :src="xc2"></b-img>
					</div>
					<div class="col-lg-5 text-center">
						<b-img class="image" style="width: 600px;height: auto;" :src="xc1"></b-img>
					</div>
				</div>
				<div class="section container">
					<div class="row" style="gap: 40px">
						<div class="col-lg-12 text-center">
							<b-img class="image" :src="xc3"></b-img>
						</div>
						<div class="col-lg-12 text-center">
							<b-img class="image" :src="xc4"></b-img>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="section container" v-if="index == 2">
			<div class="col-lg-12">
				<div class="text-center mb-5">
					<h2>宇佑船舶能耗及碳强度管理工具</h2>
				</div>
				<div class="col-lg-12">
					<p>&emsp;&emsp;根据海事局发布的《船舶能耗数据和碳强度管理办法》及《船舶能耗数据收集与报告技术要求》（JT/T
						1340），为助力国内水路运输“碳达峰”以及国际海运温室气体减排战略，强化船舶节能减排管理，加快推动航运业高质量发展，宇佑船舶开发了“船舶能耗管理工具”并接入海事局“船舶能耗报告”系统，高效准确协助船舶收集、计算和报告能耗数据。
					</p>
					<p>&emsp;&emsp;“船舶能耗管理工具”脱胎于宇佑船舶多年研发的“智能船舶解决方案”。基于多年收集分析船舶能耗数据过程中发现的诸多问题，研发人员认真研读并吃透海事局《船舶能耗数据和碳强度管理办法》及《船舶能耗数据收集与报告技术要求》（JT/T
						1340），大量走访调研了多家航运企业，并邀请航运公司参与系统的设计与测试，“船舶能耗管理工具”可以实现能耗数据的自动收集，并将日、航次数据累计与营运能效强度计算交由计算机系统执行；同时实现船舶年度营运碳强度指标
						(CII) 评级可预测、可修正。
					</p>
					<p>&emsp;&emsp;船舶能耗数据报告是MARPOL公约和《中华人民共和国船舶及其有关作业活动污染海洋环境防治管理规定》的强制性要求，要求各航运企业按照《船舶能耗数据收集管理办法》落实好能耗报告工作，未按规定报告的，海事管理机构可以责令整改、警告，甚至罚款。
					</p>
					<p>&emsp;&emsp;该系统的使用，将会极大减少船员负担，减少数据计算错误，防止因错报而被海事主管机关处罚。航运企业可以借助本系统，开展内部节能管理，降低燃料成本，避免船舶被评为能效较差等级等。
					</p>
					<p><a href="https://energy.yuyouship.com.cn/" target='_blank' rel='noopener noreferrer'>宇佑船舶能耗管理工具(在线版)</a></p>
				</div>
				<div class="section container">
					<div class="row" style="gap: 40px">
						<div class="col-lg-12 text-center">
							<b-img class="image" :src="nxtqd1"></b-img>
						</div>
						<div class="col-lg-12 text-center">
							<b-img class="image" :src="nxtqd2"></b-img>
						</div>
						<div class="col-lg-12 text-center">
							<b-img class="image" :src="nxtqd3"></b-img>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section container" v-if="index == 3">
			<div class="col-lg-12">
				<div class="text-center mb-5">
					<h2>宇佑邮轮海上生活解决方案</h2>
				</div>
				<div class="col-lg-12">
					<p>&emsp;&emsp;国际邮轮业已经进入大船时代，游客对邮轮海上生活的要求也不断提高。因此，邮轮也在升级运营及配套设施，加大对新技术的运用，以提高邮轮产品的竞争力。其中，邮轮的智慧化建设是吸引游客的重要因素之一。
					</p>
					<p>&emsp;&emsp;智慧邮轮海上生活解决方案以新一代信息技术为基础，以提高邮轮的运营效率，改善游客的旅游体验和品质为目标，进而实现邮轮运营各环节的智慧化管理和服务。</p>
					<p>&emsp;&emsp;目前，智慧邮轮信息技术主要被应用于邮轮海上生活的信息咨询、舱房服务、娱乐服务等方面。</p>
					<p>&emsp;&emsp;在娱乐服务方面，智慧邮轮将人工智能技术和VR／AR 等技术应用于娱乐设施中，提升娱乐设施的科技程度。</p>
					<p>&emsp;&emsp;在舱房服务方面，智慧邮轮将物联网技术融入舱房中，人工智能终端根据游客的指令自动调整邮轮上的设施，或者根据环境的变化做出调整，实现了舱房设施的自动化，提高了旅行的舒适性和安全性。
					</p>
					<p>&emsp;&emsp;在信息咨询方面，游客不仅可以借助互联网直接获得所用信息，通过询问人工智能终端，可以解决邮轮旅行中遇到的问题，节省大量时间。</p>
				</div>
				<div class="section container">
					<div class="row" style="gap: 40px">
						<div class="col-lg-12 text-center">
							<b-img class="image" :src="hssh1"></b-img>
						</div>
						<div class="col-lg-12 text-center">
							<b-img class="image" :src="hssh2"></b-img>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section container" v-if="index == 4">
			<div class="col-lg-12">
				<div class="text-center mb-5">
					<h2>宇佑船舶网络安全产品</h2>
				</div>
				<div class="col-lg-12">
					<p>&emsp;&emsp;上海宇佑船舶科技有限公司基于多年船舶信息化建设经验，结合前沿网络安全理念，从用户自身业务和安全运维角度出发，在保障业务安全、稳定运行的同时，结合与时俱进的安全防护体系与技术手段，
						让更多用户从等保建设中受益。</p>
					<p>&emsp;&emsp;上海宇佑船舶科技有限公司船舶网络安全产品，在满足相应等级安全物理环境、安全通信网络、安全区域边界、安全计算环境、安全管理中心及管理部分需求基础上，不断完善网络安全的分析能力、未知威胁的检测能力、安全工作的执行能力，包括系统管理、审计管理、安全管理、集中管控等关键需求。
					</p>
				</div>
				<div class="section container">
					<div class="row" style="gap: 40px">
						<div class="col-lg-12 text-center">
							<b-img class="image" style="width: 240px;height: 240px;" :src="wlaq1"></b-img>
						</div>
						<div class="col-lg-12 text-center">
							<b-img class="image" :src="wlaq2"></b-img>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="section container" v-if="index == 5">
			<div class="col-lg-12">
				<div class="text-center mb-5">
					<h2>宇佑智能船舶解决方案</h2>
				</div>
				<div class="col-lg-12">
					<p>&emsp;&emsp;智能船舶是指利用传感器、通信、物联网、互联网等技术手段，自动感知和获得船舶自身、海洋环境、物流、港口等方面的信息和数据，并基于计算机技术、自动控制技术和大数据处理和分析技术，在船舶航行、管理、维护保养、货物运输等方面实现智能化运行的船舶，以使船舶更加安全、更加环保、更加经济和更加高效。
					</p>
					<p>&emsp;&emsp;智能能效管理基于船舶航行状态、能耗状况的监测数据和信息，对船舶能效状况、航行及装载状态等进行评估，为船舶提供评估结果和航速优化、基于纵倾优化的最佳配载等解决方案，实
						现船舶能效实时监控、评估及优化，以不断提高船舶能效管理水平。</p>
					<p>&emsp;&emsp;智能机舱能综合利用状态监测所获得的各种信息和数据，对机舱内设备与系统的运行状态、 健康状况进行分析和评估，为设备与系统的使用、操作和控制、检修、管理等方面的决策提供支持。
					</p>
					<p>&emsp;&emsp;智能航行利用先进感知技术和传感信息融合技术等获取和感知船舶航行所需的状态信
						息，并通过计算机技术、控制技术进行分析和处理，为船舶的航行提供航速和航路优化的决策建议。</p>
					<p>&emsp;&emsp;智能集成平台能为智能航行、智能船体、智能机舱、智能能效管理、
						智能货物管理提供支持，形成船上数据采集/获取、存储、整合、交互、共享与展现，控制指令传输的统一集成平台且具备开放性，能够整合现有船上信息管理系统及后续新增系统，以实现对船舶的全面监控与智能化管理，并与岸基实现数据交互。
					</p>
					<p>&emsp;&emsp;宇佑智能船舶解决方案以上述模块为基础，和领先的数据化技术理念及全力的研发投入，推出全新一代智能船，可独立部署智能航行、智能机舱、智能能效系列产品。</p>
				</div>
				<div class="section container">
					<div class="row" style="gap: 40px">
						<div class="col-lg-12 text-center">
							<b-img class="image" :src="znc1"></b-img>
						</div>
						<div class="col-lg-12 text-center">
							<b-img class="image" :src="znc2"></b-img>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section container" v-if="index == 6">
			<div class="col-lg-12">
				<div class="text-center mb-5">
					<h2>宇佑船舶信息化集成</h2>
				</div>
				<div class="section container">
					<div class="row" style="gap: 20px">
						<div class="col-lg-12 text-center">
							<b-img class="image" :src="solution1"></b-img>
						</div>
						<div class="col-lg-12 text-center">
							<b-img class="image" :src="solution2"></b-img>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section container" v-if="index == 7">
			<div class="col-lg-12">
				<div class="text-center mb-3">
					<h2>宇佑船务管理系统</h2>
				</div>
				<div class="col-lg-12">
					<p>&emsp;&emsp;船务管理系统，涵盖了船务管理过程中的各项基本功能。功能包括设备管理、备件管理、物料管理、维修保养、油品管理、安全管理、工程管理、PTW管理、船员管理、服务商管理等模块。
					</p>
					<p>&emsp;&emsp;船务管理系统分成岸基版和船舶版两个版本，分别用作公司与船舶的业务管理，并通过通过卫星通信、公网通信等手段实现数据的共享，实现船岸管理的一体化。
					</p>
				</div>
				<div class="section container" style="margin-top: -40px;">
					<div class="row" style="gap: 20px">
						<div class="col-lg-12 text-center">
							<b-img class="image" :src="pms1"></b-img>
						</div>
						<div class="col-lg-12 text-center">
							<b-img class="image" :src="pms2"></b-img>
						</div>
					</div>
				</div>
			</div>
		</div>




	</section>
</template>

<script>
	import solution1 from "@/assets/images/solution1.png";
	import solution2 from "@/assets/images/solution2.png";

	import xc1 from "@/assets/images/solution/1/img1.png";
	import xc2 from "@/assets/images/solution/1/img2.png";
	import xc3 from "@/assets/images/solution/1/img3.png";
	import xc4 from "@/assets/images/solution/1/img4.png";

	import nxtqd1 from "@/assets/images/solution/2/img1.png";
	import nxtqd2 from "@/assets/images/solution/2/img2.png";
	import nxtqd3 from "@/assets/images/solution/2/img3.png";

	import hssh1 from "@/assets/images/solution/3/img1.png";
	import hssh2 from "@/assets/images/solution/3/img2.png";

	import wlaq1 from "@/assets/images/solution/4/img1.jpg";
	import wlaq2 from "@/assets/images/lunbo/img4.jpg";

	import znc1 from "@/assets/images/solution/5/img1.png";
	import znc2 from "@/assets/images/solution/5/img2.png";

	import pms1 from "@/assets/images/solution/6/img1.png";
	import pms2 from "@/assets/images/solution/6/img2.png";




	export default {
		name: "CopyRight",
		data() {
			return {
				solution1,
				solution2,
				index: 1,
				xc1,
				xc2,
				xc3,
				xc4,
				nxtqd1,
				nxtqd2,
				nxtqd3,
				hssh1,
				hssh2,
				znc1,
				znc2,
				wlaq1,
				wlaq2,
				pms1,
				pms2
			};
		},
		mounted() {

			if (location.search.toString().length != 0) {
				this.index = location.search.replace("?", "");
			}
			console.log("this.search", this.index);
		},
		methods: {
			jump(url) {
				// window.open(url)
				const newWin = window.open(url);
				newWin.opener = null;
			},
		}
	};
</script>

<style lang="less" scoped>
	.top {
		background: #273a65;
		color: white;
		padding-top: 3rem;
	}

	.top-title {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.top-title::before {
		content: "";
		width: 100px;
		height: 1px;
		background-color: white;
		position: absolute;
		margin-left: -400px;
	}

	.top-title::after {
		content: "";
		width: 100px;
		height: 1px;
		background-color: white;
		position: absolute;
		margin-left: 400px;
	}

	.image {
		width: 60%;
		height: auto;
	}
</style>
