<template>
	<nav
		:class="btnFlag ? 'navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark align-items-center nav-sticky' : 'navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark align-items-center'">
		<div class="container">
			<a class="logo mr-3" href="/home/index">
				<b-img style="width: 12rem; height: 4rem" :src="xttlogo"></b-img>
			</a>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
				aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
				<i class="" data-feather="menu"></i>
			</button>
			<div class="collapse navbar-collapse" id="navbarCollapse" style="margin-left: 10px;">
				<ul class="navbar-nav navbar-center" id="mySidenav" style="margin-left: 40px;">
					<li class="nav-item">
						<b-dropdown-item href="/home"><span style="font-weight: 300">首页</span></b-dropdown-item>
					</li>
					<li class="nav-item" @mouseover="moveIn($event)" @mouseleave="moveOut($event)">
						<b-nav-item-dropdown text="关于我们" style="font-weight: 300;">
							<b-dropdown-item href="about">公司简介</b-dropdown-item>
							<b-dropdown-item href="aptitude">企业资质</b-dropdown-item>
							<b-dropdown-item href="project">公司业绩</b-dropdown-item>
							<b-dropdown-item href="join">加入我们</b-dropdown-item>
						</b-nav-item-dropdown>
					</li>
					<li class="nav-item" @mouseover="moveIn($event)" @mouseleave="moveOut($event)">
						<b-nav-item-dropdown text="产品和解决方案" style="font-weight: 300;">
							<b-dropdown-item href="solution?1">宇佑船舶智能舱室一体化解决方案</b-dropdown-item>
							<b-dropdown-item href="solution?2">宇佑能耗及碳强度管理工具</b-dropdown-item>
							<b-dropdown-item href="solution?3">宇佑邮轮海上生活解决方案</b-dropdown-item>
							<b-dropdown-item href="solution?4">宇佑船舶网络安全产品</b-dropdown-item>
							<b-dropdown-item href="solution?5">宇佑智能船舶解决方案</b-dropdown-item>
							<b-dropdown-item href="solution?6">宇佑船舶信息化集成</b-dropdown-item>
							<!-- <b-dropdown-item href="solution?7">宇佑船务管理系统</b-dropdown-item> -->
						</b-nav-item-dropdown>
					</li>
					<!-- 				<li class="nav-item" @mouseover="moveIn($event)" @mouseleave="moveOut($event)">
						<b-nav-item-dropdown text="下载">
							<b-dropdown-item @click="handleDownload">pc离线版</b-dropdown-item>
							<b-dropdown-item href="#">web在线版</b-dropdown-item>
						</b-nav-item-dropdown>
					</li> -->
					<li class="nav-item" @mouseover="moveIn($event)" @mouseleave="moveOut($event)">
						<b-nav-item-dropdown text="新闻资讯" style="font-weight: 300;">
							<b-dropdown-item href="news">公司新闻</b-dropdown-item>
							<b-dropdown-item href="notice">公司公告</b-dropdown-item>
						</b-nav-item-dropdown>
					</li>
					<li class="nav-item">
						<b-dropdown-item href="contact"><span style="font-weight: 300">联系我们</span></b-dropdown-item>
					</li>
					<li class="nav-item" style="margin-left: 80px;margin-right: 260px;" @mouseover="moveIn($event)"
						@mouseleave="moveOut($event)">
						<b-nav-item-dropdown text="宇佑云平台" style="font-weight: 300;">
							<b-dropdown-item href="#" @click="jump('https://energy.yuyouship.com.cn/')"><span
									style="font-weight: 300">宇佑船舶能耗管理工具</span></b-dropdown-item>
					<!-- 		<b-dropdown-item href="#" @click="jump('#')"><span
									style="font-weight: 300">宇佑智能船舶平台</span></b-dropdown-item> -->
						</b-nav-item-dropdown>

					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>

<script>
	import axios from "axios";
	import {
		saveAs
	} from "file-saver";
	import xttlogo from "@/assets/images/xttlogo.png";
	export default {
		name: "GlobalHeader",
		data() {
			return {
				btnFlag: false,
				xttlogo,
			};
		},
		mounted() {
			window.addEventListener("scroll", this.scrollToTop, true);
		},
		destroyed() {
			window.removeEventListener("scroll", this.scrollToTop, true);
		},

		methods: {
			moveIn(e) {
				let ul = e.currentTarget.firstElementChild.querySelector("ul")
				ul.setAttribute("class", "dropdown-menu show")
				// ul.setAttribute("style", "padding-top:25px;")
			},
			moveOut(e) {
				let ul = e.currentTarget.firstElementChild.querySelector("ul")
				ul.setAttribute("class", "dropdown-menu")
			},
			jump(url) {
				const newWin = window.open(url);
				newWin.opener = null;
			},
			scrollToTop() {
				let that = this;
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				that.scrollTop = scrollTop;
				that.btnFlag = true;
			},
			handleDownload() {
				axios.get("/offline/installpkg?ui=pc").then(
					(response) => {
						const {
							data
						} = response.data;
						if (!!data && data.length) {
							console.log("data[0].versionId", data[0].versionId);
							this.download(data[0].versionId);
						}
						console.log("data", data);
					},
					(error) => {
						console.log(error);
					}
				);
			},
			download(versionId) {
				console.log("versionId", versionId);
				axios.get("/offline/download?versionId=" + versionId).then(
					(response) => {
						const blob = new Blob([response.data]);
						this.saveAs(blob, decodeURIComponent(response.headers["download-filename"]));
					},
					(error) => {
						console.log(error);
					}
				);
			},
			saveAs(text, name, opts) {
				saveAs(text, name, opts);
			},
		},
	};
</script>

<style lang="less" scoped>
	.b-nav-dropdown:hover .dropdown-menu {
		display: block;
	}
</style>
