<template>
  <section style="margin-bottom: 100px">
    <div class="top">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="text-center mb-5">
            <h1 class="top-title">加入我们</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container" style="margin-top: 2rem">
      <div class="row">
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
          </svg>
          邮箱：hr@yuyouship.com
        </h4>
      </div>
    </div>

    <div class="container" style="margin-top: 2rem">
      <div class="row">
        <div class="col-lg-2">
          <b-button style="width: 200px" v-b-toggle.collapse-1 variant="primary">人工智能算法科学家</b-button>
        </div>
        <b-collapse id="collapse-1" class="mt-2" visible>
          <b-card>
            <div class="row">
              <div class="col-lg-12">
                <strong>岗位职责</strong>
                <div>1.负责人工智能平台基础算法建设和算法优化工作。</div>
                <div>2、跟踪前沿算法理论和业界技术，研究领先的机器学习/深度学习算法及其理论，并结合各领域研究有效解决领域问题的算法及理论。</div>
                <div>3、负责自动化机器学习、深度学习、强化学习等新型算法的研究和建设工作。</div>
                <div>4、实现工业互联网前沿算法和技术并沉淀于人工智能平台。</div>
                <strong>任职要求：</strong>
                <div>1、 全日制博士以上学历，统计学、数学、计算机或工科教育背景，要求3年及以上相关工作经验。</div>
                <div>2、 精通传统机器学习或深度学习基础理论知识。</div>
                <div>3、熟练使用C++、Java、Python、Scala至少一门语言，熟练使用Spark ML/Tensorflow/Caffe/Mxnet/Torch等至少一个框架。</div>
                <div>4、 精通结构化数据、图像、语音、视频、自然语言处理等至少一个领域的算法，具备扎实的AI算法理论知识，能根据理论编写高效的AI算法。</div>
                <div>5、 曾在顶级期刊/会议/论坛发表论文，或者对业界做出重要贡献。</div>
                <div>6、 责任心强，具有较强的学习能力、解决问题能力，良好的沟通能力。</div>
                <div>7、 有大型互联网公司实施机器学习或深度学习相关工作经验优先。</div>
              </div>
            </div>
          </b-card>
        </b-collapse>
      </div>
    </div>

    <div class="container" style="margin-top: 2rem">
      <div class="row">
        <div class="col-lg-3">
          <b-button style="width: 200px" v-b-toggle.collapse-2 variant="primary">软件销售主管</b-button>
        </div>
        <b-collapse id="collapse-2" class="mt-2" visible>
          <b-card>
            <div class="row">
              <div class="col-lg-12">
                <strong>岗位职责</strong>
                <div>1、根据软件项目销售计划，合理分解制定目标和销售任务，组织指导下属执行，保证销售目标达成；</div>
                <div>2、贯彻企业文化理念，建立和管理销售队伍，负责团队人员的培训、选拔、考核、奖惩等管理工作；</div>
                <div>3、控制销售预算、销售费用、销售范围与销售目标的平衡发展，管控项目回款过程，监督销售回款目标达成；</div>
                <div>4、制定销售团队绩效考核制度，对销售业绩进行监督、考核，汇总整理、分析销售报表和相关数据；</div>
                <div>5、协助重要客户的维系和开拓工作，与重要客户保持良好业务关系；</div>
                <div>6、收集并整理相关的市场及客户信息，为公司的业务决策提供信息支持。</div>
                <strong>任职要求：</strong>
                <div>1、专科及以上学历，软件、信息或计算机等相关专业优先；</div>
                <div>2、三年以上销售经验，业绩突出，有较强管理能力；</div>
                <div>3、具备一定的市场分析及判断能力，良好的客户服务意识；</div>
                <div>4、很好的沟通能力和团队管理能力，具有很好的协调和抗压能力；</div>
                <div>5、责任心强，有良好团队协作精神，善于挑战。</div>
                <strong>备注：</strong>
                <div>本岗位为新兴蓝海市场岗位，非本专业人员的资深销售也可尝试。面试步骤分为电话沟通，线上答题（根据相应国家政策场景，和公司产品介绍给出销售策略或建议），现场面试。</div>
                <div>由于销售岗参差不齐，薪资仅做参考。有能力者可挑战高薪。</div>
              </div>
            </div>
          </b-card>
        </b-collapse>
      </div>
    </div>

    <div class="container" style="margin-top: 2rem">
      <div class="row">
        <div class="col-lg-3">
          <b-button style="width: 200px" v-b-toggle.collapse-3 variant="primary">Java开发工程师</b-button>
        </div>
        <b-collapse id="collapse-3" class="mt-2" visible>
          <b-card>
            <div class="row">
              <div class="col-lg-12">
                <strong>岗位职责</strong>
                <div>1、参与软件项目的系统需求分析与系统设计工作；</div>
                <div>2、完成核心代码的编写，配合项目经理，测试人员完成功能交付；</div>
                <div>3、指导初级开发人员工作；</div>
                <div>4、相关技术文档编写。</div>
                <strong>任职要求：</strong>
                <div>1、需要三年及以上java开发经验。</div>
                <div>2、需要一年及以上的java软件开发的管理经验。</div>
                <div>3、熟悉mysql数据库系统。</div>
                <div>4、具有独立承担功能模块开发的能力。</div>
                <div>5、良好的编码习惯、协调能力和团队合作精神，能承受较大的工作压力。</div>
                <div>6、具有系统设计经验和性能优化经验者优先。</div>
                <div>7、熟悉linux操作系统的操作。</div>
              </div>
            </div>
          </b-card>
        </b-collapse>
      </div>
    </div>
  </section>
</template>

<script>
import ship1 from "@/assets/images/ship1.jpg";
import ship2 from "@/assets/images/ship2.jpg";

export default {
  name: "CopyRight",
  data() {
    return {
      ship1,
      ship2,
    };
  },
};
</script>

<style lang="less" scoped>
.top {
  background: url("../assets/images/join.jpg");
  background-size: 110% 100%;
  background-repeat: no-repeat;
  color: white;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  height: 100%;
  width: 200px;
}
.top-title::before {
  content: "";
  width: 100px;
  height: 1px;
  background-color: black;
  position: absolute;
  margin-left: -300px;
}
.top-title::after {
  content: "";
  width: 100px;
  height: 1px;
  background-color: black;
  position: absolute;
  margin-left: 300px;
}
.image-card {
  width: 30rem;
  height: 330px;
  background-color: rgba(128, 128, 128, 0.1);
  position: relative;
  padding: 0;
  margin: 0;
  border: 1px solid rgba(128, 128, 128, 0.2);
  display: flex;
  justify-content: center;
}
.image {
  height: 280px;
}
.image-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
</style>
