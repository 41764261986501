<template>
	<section style="margin-bottom: 100px">
		<div class="top">
			<div class="row justify-content-center">
				<div class="col-lg-9">
					<div class="text-center mb-5"></div>
				</div>
			</div>
		</div>
		<div class="aptitude">
			<div class="row justify-content-center">
				<div class="col-lg-9">
					<div class="text-center mb-5">
						<h1 class="top-title">公司公告</h1>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="container" style="margin-top: 2rem">
      <div class="row">
        <div class="col-lg-4">
          <b-button  style="width:20rem;height: 80px;"
           @click="junpNews('https://mp.weixin.qq.com/s/MOjVnV7yrYoUcgXYHVM7WA')" variant="primary">宇佑船舶在海门投资成立新公司</b-button>
        </div>
        <b-collapse id="collapse-1" class="mt-2" visible>
          <b-card>
            <div class="row">
              <div class="col-lg-12">
                <div>近日，上海宇佑船舶科技有限公司在海门成立新公司，新公司名称为南通思诺船舶科技有限公司。</div>
                <div>南通思诺船舶科技有限公司成立的主要目的在于开发江苏市场。在加快经济转型升级和基本现代化建设中，对海门地区高新技术企业的入驻和人才引进有突出贡献，以“东洲智荟”领军型创业人才入选海门“东洲英才”引进计划，享受“东洲英才”相应的政策待遇。</div>
                <div>未来，南通思诺船舶科技有限公司将充分发挥企业优势，以提高全产业链竞争力为主要目标，为江苏省成为船舶与海工装备制造第一强省添砖加瓦。</div>
              </div>
            </div>
          </b-card>
        </b-collapse>
      </div>
    </div>

    <div class="container" style="margin-top: 2rem">
      <div class="row">
        <div class="col-lg-4">
          <b-button style="width:20rem;height: 80px;"
           @click="junpNews('https://mp.weixin.qq.com/s/0-NlwYI2oly50r6UqzHXXQ')" variant="primary">智能船舶数据解析、标准化及可视化等软件开发项目顺利通过验收</b-button>
        </div>
        <b-collapse id="collapse-2" class="mt-2" visible>
          <b-card>
            <div class="row">
              <div class="col-lg-12">
                <div>据悉上海船舶研究设计院组织专家组，对智能船舶数据解析、标准化及可视化等软件开发项目进行验收。专家组验收评定后认为，该项目圆满完成了合同要求的开发任务，各开发系统能够正常运行，已完成项目资料提交，符合项目验收要求。</div>
                <div>该项目是工信部智能船舶1.0专项衍生的DOSS数字化营运支持系统。采集船舶前端数据，与岸基平台互联是它的基本责任，它还能提供船队管理、船舶状态管理、报文服务、安全管理、能耗管理等服务。代表中国制造2025“高技术船舶”的信息化中国解决方案！</div>
              </div>
            </div>
          </b-card>
        </b-collapse>
      </div>
    </div>

    <div class="container" style="margin-top: 2rem">
      <div class="row">
        <div class="col-lg-4">
          <b-button style="width:20rem;height: 80px;"
            @click="
              junpNews(
                'https://mp.weixin.qq.com/s?__biz=Mzg5OTY3NTY1Ng==&mid=2247483686&idx=1&sn=0b90ff4a9a30a009d728d54e9c1087a3&chksm=c04ee241f7396b57a71170d40c507cb7f764f0a728f04fad351ce42cbed4e6bf25835e7ebf5f&mpshare=1&scene=1&srcid=0109zzf7RlUQR9PtaWfoStUW&sharer_sharetime=1673258030639&sharer_shareid=18cab1b19f20b178fa41212c1cb03383&key=5e5dd40d301dad9b2f03bffd0bbece0471838a3a793a86a235958481206c5c9f64d22e446bdc38115508ccecb9427eebece013563ef26ca8926d7e4af6d7558c17e42f8cba3a4550cbdb7756ad7888411899deae543bd5ef4ee436c3bb4bba54861fbc960383853bf715658e63c9cebd3fdcaa7ef6acc969625068118963ba6e&ascene=1&uin=MTU1Mjg1NTUzNg%3D%3D&devicetype=Windows+11+x64&version=6308011a&lang=zh_CN&exportkey=n_ChQIAhIQgGSLmm%2FLUxHbuV3Lu4fbKBLqAQIE97dBBAEAAAAAAARmGLXEr2YAAAAOpnltbLcz9gKNyK89dVj09qv1h3fOqI8RftAH2rqnulfy80STSshZMJz0uDrermgOT58mafuzHW%2Blbmu68KhbOhqNqkshKU24HWDHumlq9dq4xnmIaWJwT2rcJ1okQkPAlYxDy2NHEH%2BOcI%2FjupjX0TpTUWbPc4v7d1NwS7mijtFjs1kYst2HjGf3gUNfX9%2B5ONaM%2Bw8I0XZwICv6HtT2PL5Hb58U2TdyQkgu25GNDnZeI557ZwJ4GIfjWLxCJ%2BUhfSzPo9cqT8V4yVP3Q1XqtZpGtg%3D%3D&acctmode=0&pass_ticket=IBqK%2BdrIDZHNMOc6OL2F9TcXdUrFj%2Fl9FHQoU0BMZkGqGs3l%2BhV052a2HCzMo0ZeK3Kw6T%2BXiNm4J58SKS8edw%3D%3D&wx_header=1&fontgear=2'
              )
            "
            variant="primary"
            >宇佑船舶携手紫光物联（UIOT）共助海洋智慧生活发展</b-button
          >
        </div>
        <b-collapse id="collapse-3" class="mt-2" visible>
          <b-card>
            <div class="row">
              <div class="col-lg-12">
                <div>1月27日，上海宇佑船舶科技有限公司与上海紫光乐联物联网科技有限公司签署战略合作协议，依托宇佑船舶“智慧船舶”科技生态，双方将充分发挥各自优势，共同打造资源共享、国内一流的海洋智慧生活平台。</div>
                <div>上海宇佑船舶科技有限公司成立于2018年5月，注册资金2000 万元。公司专注于“智慧船舶”以及“船舶新材料”相关产品的研发、设计、生产和销售，为船舶行业提供智能化信息化解决方案。</div>
                <div>上海紫光乐联物联网科技有限公司是一家以产品驱动和消费者需求驱动的技术型公司，核心是提供稳定、高质量、优秀体验的产品。旗下UIOT超级智慧家品牌为国内全屋智能家居第一品牌。</div>
                <div>目前，在船舶上大部分的情况是人被动地适应环境。如何把智能家居相关技术与船舶居住空间设计有机结合起来，让“环境去适应人”从而提高用户的舒适度是未来海洋智慧生活发展的重要方向之一。在“智慧船舶”中，智能家居的运用占了很大部分，对“智慧船舶”的发展有着重要的推动作用，未来“智慧船舶”市场将吸引很多高科技企业进入，带来可观的经济效益。</div>
              </div>
            </div>
          </b-card>
        </b-collapse>
      </div>
    </div> -->
	</section>
</template>

<script>
	export default {
		name: "CopyRight",
		data() {
			return {};
		},
		methods: {
			junpNews(url) {
				// window.open(url, "_blank");
				const newWin = window.open(url);
				newWin.opener = null;
			},
		},
	};
</script>

<style lang="less" scoped>
	.aptitude {
		background: #273a65;
		color: white;
		padding-top: 3rem;
	}

	.top {
		background: url("../assets/images/notice.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
		color: white;
		height: 300px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.top-title {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.top-title::before {
		content: "";
		width: 100px;
		height: 1px;
		background-color: white;
		position: absolute;
		margin-left: -300px;
	}

	.top-title::after {
		content: "";
		width: 100px;
		height: 1px;
		background-color: white;
		position: absolute;
		margin-left: 300px;
	}

	.image-card {
		width: 30rem;
		height: 330px;
		background-color: rgba(128, 128, 128, 0.1);
		position: relative;
		padding: 0;
		margin: 0;
		border: 1px solid rgba(128, 128, 128, 0.2);
		display: flex;
		justify-content: center;
	}

	.image {
		height: 280px;
	}

	.image-title {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 50px;
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
		color: black;
	}
</style>
