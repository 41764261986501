import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'

import router from './router'
import store from './store'

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// // //引入样式
// import '../src/assets/css/swiper.min.css'
// import './assets/css/swiper-3.4.2.min.css'
// Vue.use(VueAwesomeSwiper,)/* { 全局组件的默认选项 } */


// 引入css样式
import './plugins/bootstrapvue'                  // 引入 bootstrap-vue
import 'zico/css/zico.min.css'                   // zico 图标库 css
import 'github-markdown-css/github-markdown.css' // markdown css
import './assets/css/animate.css'
import './assets/css/style.css'

// 挂载 axios
Vue.prototype.$http = axios

Vue.config.productionTip = false
// 配置 Ajax 请求根地址
axios.defaults.baseURL = 'https://api.yuyouship.cn'

// 代码高亮
import hljs from 'highlight.js'
import 'highlight.js/styles/github.css'
Vue.directive('highlight', function (el) {
  let blocks = el.querySelectorAll('pre code');
  blocks.forEach((block) => {
    hljs.highlightBlock(block)
  })
})



new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
