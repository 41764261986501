<template>
	<section class="footer" style="margin-bottom: 10px;">
		<div class="container">
			<div class="row">
				<div class="col-lg-2">
					<b-img style="width: 10rem; height: 10rem" :src="barcode"></b-img>
				</div>
				<div class="col-lg-3">
					<div class="mb-5">
						<h4 class="f-22 font-weight-normal mb-3">联系方式</h4>

						<div class="row">
							<div class="col-md-12">
								<div>上海宇佑船舶科技有限公司</div>
								<div>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
										class="bi bi-geo-alt" viewBox="0 0 16 16">
										<path
											d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
										<path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
									</svg>
									地址(上海总部)： 上海市浦东新区海港大道1555号T1塔楼703室
								</div>
								<div>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
										class="bi bi-phone-fill" viewBox="0 0 16 16">
										<path
											d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z" />
									</svg>
									电话：021-61090203
								</div>
								<div>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
										class="bi bi-envelope" viewBox="0 0 16 16">
										<path
											d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
									</svg>
									邮箱：zixun@yuyouship.com
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div>南通思诺船舶科技有限公司</div>
							<div>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
									class="bi bi-geo-alt" viewBox="0 0 16 16">
									<path
										d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
									<path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
								</svg>
								地址： 江苏省南通市海门经济技术开发区上海路866号邮轮研究院
							</div>
							<div>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
									class="bi bi-phone-fill" viewBox="0 0 16 16">
									<path
										d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z" />
								</svg>
								电话： 0513-20171101
							</div>
							<div>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
									class="bi bi-envelope" viewBox="0 0 16 16">
									<path
										d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
								</svg>
								邮箱： zixun@yuyouship.com
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6 offset-lg-1">
					<div class="row">
						<div class="col-md-3">
							<h4 class="f-22 font-weight-normal mb-3">关于我们</h4>
							<ul class="list-unstyled footer-sub-menu">
								<li><a href="aptitude" class="footer-link">公司简介</a></li>
								<li><a href="aptitude" class="footer-link">企业资质</a></li>
								<li><a href="project" class="footer-link">公司业绩</a></li>
								<li><a href="join" class="footer-link">加入我们</a></li>
							</ul>
						</div>

						<div class="col-md-5">
							<h4 class="f-22 font-weight-normal mb-3">产品和解决方案</h4>
							<ul class="list-unstyled footer-sub-menu">
								<li><a href="solution?1" class="footer-link">宇佑船舶智能舱室一体化解决方案</a></li>
								<li><a href="solution?2" class="footer-link">宇佑能耗及碳强度管理工具</a></li>
								<li><a href="solution?3" class="footer-link">宇佑邮轮海上生活解决方案</a></li>
								<li><a href="solution?4" class="footer-link">宇佑船舶网络安全产品</a></li>
								<li><a href="solution?5" class="footer-link">宇佑智能船舶解决方案</a></li>
								<li><a href="solution?6" class="footer-link">宇佑船舶信息化集成</a></li>
								<!-- <li><a href="solution?6" class="footer-link">宇佑船务管理系统</a></li> -->
							</ul>
						</div>

						<div class="col-md-3">
							<h4 class="f-22 font-weight-normal mb-3">新闻资讯</h4>
							<ul class="list-unstyled footer-sub-menu">
								<li><a href="news" class="footer-link">公司新闻</a></li>
								<li><a href="notice" class="footer-link">公司公告</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import axios from "axios";
	import {
		saveAs
	} from "file-saver";
	import barcode from "@/assets/images/barcode.jpg";
	export default {
		name: "GlobalFooter",
		data() {
			return {
				barcode,
			};
		},
		methods: {
			handleDownload() {
				axios.get("/offline/installpkg?ui=pc").then(
					(response) => {
						const {
							data
						} = response.data;
						if (!!data && data.length) {
							console.log("data[0].versionId", data[0].versionId);
							this.download(data[0].versionId);
						}
						console.log("data", data);
					},
					(error) => {
						console.log(error);
					}
				);
			},
			download(versionId) {
				console.log("versionId", versionId);
				axios.get("/offline/download?versionId=" + versionId).then(
					(response) => {
						const blob = new Blob([response.data]);
						this.saveAs(blob, decodeURIComponent(response.headers["download-filename"]));
					},
					(error) => {
						console.log(error);
					}
				);
			},
			saveAs(text, name, opts) {
				saveAs(text, name, opts);
			},
		},
	};
</script>

<style lang="less" scoped>
	.customer-footer {
		background-color: #273a65;
	}
</style>
