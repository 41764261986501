<template>
  <div>
    <GlobalHeader />
    <section class="hero-5-bg position-relative">
      <router-view />
    </section>
    <!-- <GlobalFooter /> -->
    <CopyRight />
    <LeftFloat />
  </div>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader";
// import GlobalFooter from "@/components/GlobalFooter";
import CopyRight from "../components/copyRight.vue";
import LeftFloat from "../components/LeftFloat.vue";

export default {
  name: "BasicLayout",
  components: {
    GlobalHeader,
    // GlobalFooter,
    CopyRight,
    LeftFloat,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
