<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  methods: {

  }
}
</script>

<style lang="less" scoped>
</style>
