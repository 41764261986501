<template>
  <section>
    <div class="mb-5">
      <div class="aptitude">
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="text-center mb-5">
              <h1 class="aptitude-title">企业资质</h1>
            </div>
          </div>
          <div class="text-center col-lg-12"></div>
        </div>
      </div>

      <div class="section container">
        <div class="row" style="gap: 20px">
          <div class="col-lg-4 row justify-content-center">
            <div class="image-card">
              <b-img class="image" :src="aptitiude4"></b-img>
              <div class="image-title">AAA级质量服务诚信单位</div>
            </div>
          </div>
          <div class="col-lg-4 row justify-content-center">
            <div class="image-card">
              <b-img class="image" :src="aptitiude5"></b-img>
              <div class="image-title">宇佑-ISO27001认证</div>
            </div>
          </div>

          <div class="col-lg-4 row justify-content-center">
            <div class="image-card">
              <b-img class="image" :src="aptitiude8"></b-img>
              <div class="image-title">宇佑-ISO9001</div>
            </div>
          </div>
          <div class="col-lg-4 row justify-content-center">
            <div class="image-card">
              <b-img class="image" :src="aptitiude10"></b-img>
              <div class="image-title">宇佑-ISO20000认证</div>
            </div>
          </div>
          <div class="col-lg-4 row justify-content-center">
            <div class="image-card">
              <b-img class="image" :src="aptitiude12"></b-img>
              <div class="image-title">发明专利证书-2018110712157船舶社交方法、装置、电子设备和可读存储介质</div>
            </div>
          </div>

          <div class="col-lg-4 row justify-content-center">
            <div class="image-card">
              <b-img class="image" :src="aptitiude15"></b-img>
              <div class="image-title">高新技术企业证书</div>
            </div>
          </div>
        </div>
      </div>
      <div class="aptitude">
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="text-center mb-5">
              <h1 class="aptitude-title">知识产权</h1>
            </div>
          </div>
          <div class="text-center col-lg-12"></div>
        </div>
      </div>

      <div class="section container">
        <div class="row" style="gap: 20px">
          <div class="col-lg-3 row justify-content-center" v-for="(item, index) in softwareList" :key="index">
            <div class="image-card">
              <b-img class="image" :src="item.img"></b-img>
              <div class="image-title">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import aptitiude4 from "@/assets/images/aptitude/4.jpg";
import aptitiude5 from "@/assets/images/aptitude/5.jpg";
import aptitiude8 from "@/assets/images/aptitude/8.jpg";
import aptitiude10 from "@/assets/images/aptitude/10.jpg";
import aptitiude12 from "@/assets/images/aptitude/12.jpg";
import aptitiude15 from "@/assets/images/aptitude/15.jpg";

import software1 from "@/assets/images/software/software1.jpg";
import software2 from "@/assets/images/software/software2.jpg";
import software3 from "@/assets/images/software/software3.jpg";
import software4 from "@/assets/images/software/software4.jpg";
import software5 from "@/assets/images/software/software5.jpg";
import software6 from "@/assets/images/software/software6.jpg";
import software7 from "@/assets/images/software/software7.jpg";
import software8 from "@/assets/images/software/software8.jpg";
import software9 from "@/assets/images/software/software9.jpg";
import software10 from "@/assets/images/software/software10.jpg";
import software11 from "@/assets/images/software/software11.jpg";
import software12 from "@/assets/images/software/software12.jpg";
import software13 from "@/assets/images/software/software13.jpg";
import software14 from "@/assets/images/software/software14.jpg";
import software15 from "@/assets/images/software/software15.jpg";
import software16 from "@/assets/images/software/software16.jpg";
import software17 from "@/assets/images/software/software17.jpg";
import software18 from "@/assets/images/software/software18.jpg";
import software19 from "@/assets/images/software/software19.jpg";
import software20 from "@/assets/images/software/software20.jpg";
export default {
  name: "CopyRight",
  data() {
    return {
      aptitiude4,
      aptitiude5,
      aptitiude8,
      aptitiude10,
      aptitiude12,
      aptitiude15,
      conentHeight: 855,
      softwareList: [
        {
          name: "CRM 客户关系管理系统V1.0",
          img: software1,
        },
        {
          name: "岸到岸无人航行操作系统",
          img: software2,
        },
        {
          name: "船用实时视频直播网络管理系统",
          img: software3,
        },
        {
          name: "海上无人机巡检管理系统",
          img: software4,
        },
        {
          name: "基于地图离线式的海图定位系统",
          img: software5,
        },
        {
          name: "基于区块链的船舶信息传输通讯系统",
          img: software6,
        },
        {
          name: "基于深度学习的船基数据分析软件",
          img: software7,
        },
        {
          name: "集成电路验证分析系统V1.0",
          img: software8,
        },
        {
          name: "魔镜电视系统V1.0",
          img: software9,
        },
        {
          name: "设备数据管理系统V1.0",
          img: software10,
        },
        {
          name: "实船数据可视化软件",
          img: software11,
        },
        {
          name: "网络安全管理系统V1.0",
          img: software12,
        },
        {
          name: "网络等保管理系统V1.0",
          img: software13,
        },
        {
          name: "宇佑船用智能舱室V1.0",
          img: software14,
        },
        {
          name: "宇佑服务器虚拟化软件V1.0",
          img: software15,
        },
        {
          name: "宇佑豪华邮轮客舱Android APP软件V1.0",
          img: software16,
        },
        {
          name: "宇佑豪华邮轮智能电视Android APP软件V1.0",
          img: software17,
        },
        {
          name: "宇佑云桌面管理系统软件-电子证书",
          img: software18,
        },
        {
          name: "智慧实验室系统V1.0",
          img: software19,
        },
        {
          name: "智能施工终端管理系统V1.0",
          img: software20,
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("resize", this.changeHeight());
    this.changeHeight();
  },
  methods: {
    changeHeight() {
      this.conentHeight = window.innerHeight - 96 - 26;
      console.log(" this.conentHeight", this.conentHeight);
    },
  },
};
</script>

<style lang="less" scoped>
.aptitude {
  background: #273a65;
  color: white;
  padding-top: 3rem;
}
.about {
  padding-top: 80px;
  color: white;
}
.group-title,
.about-title,
.aptitude-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.group-title::before {
  content: "";
  width: 100px;
  height: 1px;
  background-color: #0068b6;
  position: absolute;
  margin-left: -300px;
}
.about-title::before,
.aptitude-title::before {
  content: "";
  width: 100px;
  height: 1px;
  background-color: white;
  position: absolute;
  margin-left: -300px;
}
.about-title::after,
.aptitude-title::after {
  content: "";
  width: 100px;
  height: 1px;
  background-color: white;
  position: absolute;
  margin-left: 300px;
}
.image-card {
  width: 30rem;
  height: 330px;
  background-color: rgba(128, 128, 128, 0.1);
  position: relative;
  padding: 0;
  margin: 0;
  border: 1px solid rgba(128, 128, 128, 0.2);
  display: flex;
  justify-content: center;
}
.image {
  height: 280px;
}
.image-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 0 8px;
}
.about1 {
  position: relative;
}
.about1-img {
  position: absolute;
}
</style>
