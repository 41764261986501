<template>
	<div class="left-float">
		<b-button variant="primary" class="btn-circle"
			@click="jump('https://work.weixin.qq.com/kfid/kfc707ad39a686aa258')">
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
				class="bi bi-chat-square-dots" viewBox="0 0 16 16">
				<path
					d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
				<path
					d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
			</svg>
		</b-button>

		<b-button variant="primary" class="btn-circle" v-b-popover.hover="'021-61090203'">
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone"
				viewBox="0 0 16 16">
				<path
					d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
			</svg>
		</b-button>

		<b-button variant="primary" class="btn-circle" style="position: fixed;right: 20px;bottom: 60px;"
			v-show="btnFlag" @click="backTop">
			<svg t="1675837539825" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
				p-id="5102" width="32" height="32">
				<path
					d="M527.019 202.159c-3.98-3.987-9.385-6.225-15.02-6.225s-11.037 2.238-15.022 6.225l-154.8 154.795c-8.295 8.295-8.295 21.745 0 30.04 8.3 8.297 21.747 8.297 30.042 0l139.78-139.775 139.77 139.775c4.15 4.15 9.59 6.225 15.025 6.225s10.875-2.075 15.025-6.225c8.295-8.295 8.295-21.745 0-30.04L527.019 202.159 527.019 202.159zM116.095 596.078l90.867 0 0 231.984 42.485 0L249.447 596.078l90.867 0 0-42.48L116.095 553.598 116.095 596.078zM574.634 553.599 462.086 553.599c-13.587 0-27.18 5.185-37.547 15.55-10.372 10.37-15.555 23.96-15.555 37.555l0 168.25c0 13.595 5.182 27.185 15.555 37.555 10.367 10.37 23.96 15.555 37.547 15.555l112.547 0c13.59 0 27.185-5.185 37.555-15.555 10.37-10.37 15.55-23.96 15.55-37.555L627.738 606.708c0-13.6-5.18-27.185-15.55-37.56C601.818 558.784 588.224 553.599 574.634 553.599L574.634 553.599zM585.258 774.953c0 2.01-0.55 4.945-3.11 7.515-2.57 2.57-5.51 3.11-7.515 3.11L462.086 785.578c-2.005 0-4.935-0.54-7.505-3.11s-3.112-5.505-3.112-7.515L451.469 606.708c0-2.01 0.542-4.945 3.112-7.515s5.5-3.115 7.505-3.115l112.547 0c2.005 0 4.945 0.545 7.505 3.115 2.57 2.57 3.12 5.505 3.12 7.515L585.258 774.953 585.258 774.953zM892.348 569.148c-10.37-10.36-23.965-15.55-37.555-15.55L709.138 553.598l0 274.464 42.48 0 0-120.38 103.175 0c13.595 0 27.185-5.185 37.555-15.555s15.555-23.96 15.555-37.55l0-47.875C907.903 593.108 902.718 579.523 892.348 569.148L892.348 569.148zM865.418 654.578c0 2.005-0.54 4.94-3.11 7.51s-5.51 3.11-7.51 3.11l-103.17 0 0-69.12 103.17 0c2.005 0 4.94 0.545 7.51 3.115s3.11 5.505 3.11 7.515L865.418 654.578 865.418 654.578z"
					fill="#ffffff" p-id="5103" data-spm-anchor-id="a313x.7781069.0.i10" class="selected"></path>
			</svg>
		</b-button>



	</div>
</template>

<script>
	export default {
		name: "GlobalHeader",
		data() {
			return {
				scrollTop: 0, //默认距离顶部的距离
				btnFlag: false, //控制返回顶部按钮的显隐
			};
		},
		computed: {

		},
		mounted() {
			window.addEventListener('scroll', this.scrollToTop)
		},
		destroyed() {
			window.removeEventListener('scroll', this.scrollToTop)
		},
		methods: {
			jump(target) {
				const newWin = window.open(target);
				newWin.opener = null;
			},
			// 点击图片回到顶部方法，加计时器是为了过渡顺滑
			backTop() {
				const that = this
				document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop = 0
				// let timer = setInterval(() => {
				// 	// let ispeed = Math.floor(-that.scrollTop / 3)
				// 	let ispeed = -120
				// 	document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
				// 	if (that.scrollTop === 0 || that.scrollTop < 2) {
				// 		document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop = 0
				// 		clearInterval(timer)
				// 	}
				// }, 16)
				
			},

			// 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
			scrollToTop() {
				const that = this
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				that.scrollTop = scrollTop
				if (that.scrollTop > 60) {
					that.btnFlag = true
				} else {
					that.btnFlag = false
				}
			}

		},
	};
</script>

<style lang="less" scoped>
	.left-float {
		position: fixed;
		right: 20px;
		top: 50%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}

	.btn-circle {
		border-radius: 50%;
		width: 50px;
		height: 50px;
		padding: 0;
	}
</style>
