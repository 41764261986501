<template>
	<section style="margin-bottom: 100px">
		<div class="top">
			<div class="row justify-content-center">
				<div class="col-lg-9">
					<div class="text-center mb-5">
						<h1 class="top-title">联系我们</h1>
					</div>
				</div>
			</div>
		</div>

		<div class="container col-lg-12" style="margin-top: 2rem; display: flex;flex-direction: column;">
			<div class="row" style="align-items: center;justify-content: space-between;margin-bottom: 60px;">
				<div class="col-lg-4">
					<div class="col-md-12"
						style="height: 100%; display: flex; flex-direction: column; justify-content: center">
						<h3>上海宇佑船舶科技有限公司</h3>
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
								class="bi bi-geo-alt" viewBox="0 0 16 16">
								<path
									d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
								<path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
							</svg>
							地址(上海总部)： 上海市浦东新区海港大道1555号T1塔楼703室
						</div>
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
								class="bi bi-phone-fill" viewBox="0 0 16 16">
								<path
									d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z" />
							</svg>
							电话： 021-61090203
						</div>
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
								class="bi bi-envelope" viewBox="0 0 16 16">
								<path
									d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
							</svg>
							邮箱： zixun@yuyouship.com
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<b-img style="width: 25rem" :src="map1"></b-img>
				</div>
				<div class="col-lg-3">
					<div class="col-md-12"
						style="height: 100%; display: flex; flex-direction: column; justify-content: center">
						<h3>商务咨询</h3>
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
								class="bi bi-envelope" viewBox="0 0 16 16">
								<path
									d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
							</svg>
							邮箱：zixun@yuyouship.com
						</div>
						<br />
						<b-img style="width: 10rem" :src="zixun"></b-img>
						<br />
						<b-button
							style="width: 10rem; height: 40px; display: flex; justify-content: center; align-items: center"
							@click="junpNews('https://work.weixin.qq.com/kfid/kfc707ad39a686aa258')" variant="primary">
							联系我</b-button>
					</div>
				</div>
			</div>
			<div class="row" style="align-items: center;justify-content: space-between;">
				<div class="col-lg-4">
					<div class="col-md-12"
						style="height: 100%; display: flex; flex-direction: column; justify-content: center">
						<h3>南通思诺船舶科技有限公司</h3>
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
								class="bi bi-geo-alt" viewBox="0 0 16 16">
								<path
									d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
								<path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
							</svg>
							地址： 江苏省南通市海门经济技术开发区上海路866号邮轮研究院
						</div>
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
								class="bi bi-phone-fill" viewBox="0 0 16 16">
								<path
									d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z" />
							</svg>
							电话： 0513-20171101
						</div>
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
								class="bi bi-envelope" viewBox="0 0 16 16">
								<path
									d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
							</svg>
							邮箱： zixun@yuyouship.com
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<b-img style="width: 25rem" :src="map2"></b-img>
				</div>
				<div class="col-lg-3">
					<div class="col-md-12"
						style="height: 100%; display: flex; flex-direction: column; justify-content: center">
						<h3>技术支持</h3>
						<div>
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
								class="bi bi-envelope" viewBox="0 0 16 16">
								<path
									d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
							</svg>
							邮箱：support@yuyouship.com
						</div>
						<br />
						<b-img style="width: 10rem" :src="jishu"></b-img>
						<br />
						<b-button
							style="width: 10rem; height: 40px; display: flex; justify-content: center; align-items: center"
							@click="junpNews('https://work.weixin.qq.com/kfid/kfc81daf353d1129b28')" variant="primary">
							联系我</b-button>
					</div>
				</div>
			</div>
			<br />
		</div>
	</section>
</template>

<script>
	import map1 from "@/assets/images/map1.png";
	import map2 from "@/assets/images/map2.png";
	import jishu from "@/assets/images/jishu.png";
	import zixun from "@/assets/images/zixun.png";
	export default {
		name: "CopyRight",
		data() {
			return {
				map1,
				map2,
				jishu,
				zixun,
			};
		},
		methods: {
			junpNews(url) {
				// window.open(url, "_blank");
				const newWin = window.open(url);
				newWin.opener = null;
			},
		},
	};
</script>

<style lang="less" scoped>
	.top {
		background: url("../assets/images/contact.png");
		background-size: 110% 100%;
		background-repeat: no-repeat;
		color: white;
		height: 300px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.top-title {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		height: 100%;
		width: 200px;
	}

	.top-title::before {
		content: "";
		width: 100px;
		height: 1px;
		background-color: white;
		position: absolute;
		margin-left: -300px;
	}

	.top-title::after {
		content: "";
		width: 100px;
		height: 1px;
		background-color: white;
		position: absolute;
		margin-left: 300px;
	}

	.image-card {
		width: 30rem;
		height: 330px;
		background-color: rgba(128, 128, 128, 0.1);
		position: relative;
		padding: 0;
		margin: 0;
		border: 1px solid rgba(128, 128, 128, 0.2);
		display: flex;
		justify-content: center;
	}

	.image {
		height: 280px;
	}

	.image-title {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 50px;
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
		color: black;
	}
</style>
