import Vue from "vue";
import Router from "vue-router";
import { BasicLayout } from "@/layouts";

import Home from "../pages/Home.vue";
import About from "../pages/about.vue";
import Aptitude from "../pages/aptitude.vue";
import Project from "../pages/project.vue";
import Join from "../pages/join.vue";
import Solution from "../pages/solution.vue";
import News from "../pages/news.vue";
import Contact from "../pages/contact.vue";
import Notice from "../pages/notice.vue";
Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: BasicLayout,
      redirect: "/home/index",
      children: [
        {
          path: "/home",
          name: "home",
          component: { render: (h) => h("router-view") },
          redirect: "/home/index",
          children: [
            {
              path: "/home/index",
              component: Home,
            },
            {
              path: "/home/about",
              component: About,
            },
            {
              path: "/home/aptitude",
              component: Aptitude,
            },
            {
              path: "/home/project",
              component: Project,
            },
            {
              path: "/home/join",
              component: Join,
            },
            {
              path: "/home/solution",
              component: Solution,
            },
            {
              path: "/home/news",
              component: News,
            },
            {
              path: "/home/notice",
              component: Notice,
            },
            {
              path: "/home/contact",
              component: Contact,
            },
          ],
        },
      ],
    },
  ],
});
