<template>
  <div class="copyright">
    <p>Copyright © 2023 上海宇佑船舶科技有限公司 版权所有 &emsp;&emsp; 
      <b-img style="width: 20px; height: 20px" :src="recording">&emsp;</b-img>
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019500" target='_blank' rel='noopener noreferrer' style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">沪公网安备 31011502019500号&emsp;&emsp;</a>
      <b-img style="width: 20px; height: 20px" :src="recording">&emsp;</b-img>
      <a href="https://beian.miit.gov.cn/" target='_blank' rel='noopener noreferrer' style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">沪ICP备2023000617号-4</a>
     
    </p>
  </div>
</template>

<script>
import recording from "@/assets/images/recording.png";
export default {
  name: "CopyRight",
  data() {
    return { recording };
  },
};
</script>

<style lang="less" scoped>
.copyright {
  position: fixed;
  bottom: 0;
  height: 2rem;
  width: 100%;
  background: white;
  text-align: center;
  z-index: 999;
}
</style>
