<template>
	<div>
		<!-- 		<section class="section" style="padding-bottom: 0">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-9">
						<div class="text-center mb-5">
							<h1 class="text-black">宇佑船舶</h1>
							<h4 class="text-muted p_t_80px">上海宇佑船舶科技有限公司</h4>
						</div>
					</div>
					<div class="text-center col-lg-12">
						<b-button variant="secondary">
							<b-img style="width: 1.5rem; height: 1.5rem" :src="logo" @load="changeHeight"></b-img>
							web在线版体验
						</b-button>
						<b-button @click="handleDownload" style="margin-left: 2rem" variant="primary">pc离线版下载</b-button>
					</div>
				</div>
				<b-carousel id="carousel-1" :interval="2000" label-next="" label-prev="" controls indicators fade
					background="#ababab" style="text-shadow: 1px 1px 2px #333;height: 600px;overflow: hidden;">
					<a v-for="(img,index) in imgList" :key="index" :href="'solution?'+img.id">
						<b-carousel-slide :caption="img.caption" :text="img.text">
							<template #img>
								<img class="d-block img-fluid" style="width: auto;height: 600px;margin: auto;"
									:src="img.src" alt="image slot">
							</template>
						</b-carousel-slide>
					</a>
				</b-carousel>
			</div>
		</section> -->

		<section class="section">
			<div class="carousel" ref="carousel">
				<div class="whole">
					<div class="roll-img">
						<span class="last" @click="after" v-show="showIndex">
							&lt; </span>
						<!-- 图片容器 -->
						<ul id="ul">
							<li v-on:click="change($event)" v-for="(item, index) in imgList" :key="index"
								:class="classes[index]">
								<a :href="classes[index] == 'center' ? 'solution?'+item.id : '#'" class="acell">
									<img :src="item.src" alt="">
									<div class="caption" :class="classes[index] == 'center' ? 'show' : ''">
										<span>{{item.caption}}</span>
									</div>
								</a>
							</li>
						</ul>
						<span class="next" @click="before" v-show="showIndex">&gt;</span>
					</div>
				</div>
				<!-- 指示灯 -->
				<div class="list">
					<span class="btn" v-for="(item, index) in classes" :key="index"
						:class="[item == 'center' ? 'active' : '']" @click="changeItem(index)"></span>
				</div>
			</div>
		</section>

		<!-- 		<section class="section bg-light" style="padding-top: 0; margin-top: 20px; padding-bottom: 30px">
			<div style="background: #fff; position: absolute; left: 0; right: 0; height: 180px"></div>
			<div class="container">
				<div class="row">
					<div class="col-lg-12 text-center" style="z-index: 500">
						<b-img style="width: 60%; padding-bottom: 40px" :src="home1"></b-img>
					</div>
				</div>
			</div>
		</section> -->

		<section class="section bg-light" style="padding-top: 0; margin-top: 20px; padding-bottom: 30px">
			<video width="100%" :height="conentHeight" autoplay loop muted>
				<source :src="videoUrl" type="video/mp4" />
			</video>
		</section>


		<GlobalFooter />
	</div>
</template>

<script>
	import axios from "axios";
	import {
		saveAs
	} from "file-saver";
	import GlobalFooter from "@/components/GlobalFooter";
	// import home1 from "@/assets/images/home1.png";
	import logo from "@/assets/images/logo.png";

	// import lb1 from "@/assets/images/solution/1/img4.png";
	// import lb2 from "@/assets/images/solution/2/img2.png";
	// import lb3 from "@/assets/images/solution/3/img1.png";
	// import lb5 from "@/assets/images/solution/5/img2.png";
	// import lb4 from "@/assets/images/solution/4/img1.jpg";
	// import lb4 from "@/assets/images/solution/1/img4.png";


	import lb1 from "@/assets/images/lunbo/img1.png";
	import lb2 from "@/assets/images/lunbo/img2.jpg";
	import lb3 from "@/assets/images/lunbo/img3.jpg";
	import lb4 from "@/assets/images/lunbo/img4.jpg";
	import lb5 from "@/assets/images/lunbo/img5.jpg";



	export default {
		name: "Home",
		components: {
			GlobalFooter,
			// CarouselCopy,
		},
		data() {
			return {
				logo,
				height: "100%",
				conentHeight: 855,
				imgList: [{
					id: 1,
					caption: '宇佑船舶智能舱室一体化解决方案',
					text: '',
					src: lb1
				} ,{
					id: 5,
					caption: '宇佑智能船舶解决方案',
					text: '',
					src: lb5
				},{
					id: 2,
					caption: '宇佑船舶能效及碳强度管理工具',
					text: '',
					src: lb2
				}, {
					id: 3,
					caption: '宇佑邮轮海上生活解决方案',
					text: '',
					src: lb3
				}, {
					id: 4,
					caption: '宇佑船舶网络安全产品',
					text: '',
					src: lb4
				}],
				showIndex: false, // 是否显示左右按钮
				classes: [ 'before1','left', 'center', 'right', 'after1'],
				timer: null,
				videoUrl: "yuyou-brand.mp4"
			};
		},
		mounted() {
			let host = location.host
			if(host.indexOf("localhost") != -1){
				this.videoUrl = 'https://yuyouship.com.cn/yuyou-brand.mp4'
			}else{
				this.videoUrl = "https://" + host + '/' + this.videoUrl
			}
			window.addEventListener("resize", this.changeHeight);
			this.changeHeight();
			var that = this;
			this.$refs.carousel.addEventListener('mouseover', function() {
				that.showIndex = true;
				clearInterval(that.timer)
			})
			this.$refs.carousel.addEventListener('mouseout', function() {
				that.showIndex = false;
				that.timer = setInterval(() => {
					that.before();
				}, 3000)
			})
			this.timer = setInterval(() => {
				this.before();
			}, 3000)
		},
		beforeDestroy() {
			clearInterval(this.timer)
		},
		methods: {
			changeItem(index){
				this.classes[index] = 'center'
				if(index == 0){
					this.classes[index+1] = 'right'
					this.classes[index+2] = 'after1'
					this.classes[index+3] = 'before1'
					this.classes[index+4] = 'left'
				}else if(index == 1){
					this.classes[index-1] = 'left'
					this.classes[index+1] = 'right'
					this.classes[index+2] = 'after1'
					this.classes[index+3] = 'before1'
				}else if(index == 2){
					this.classes[index-2] = 'before1'
					this.classes[index-1] = 'left'
					this.classes[index+1] = 'right'
					this.classes[index+2] = 'after1'
				}else if(index == 3){
					this.classes[index-1] = 'left'
					this.classes[index-2] = 'before1'
					this.classes[index+1] = 'right'
					this.classes[index-3] = 'after1'
				}else if(index == 4){
					this.classes[index-1] = 'left'
					this.classes[index-2] = 'before1'
					this.classes[index-3] = 'after1'
					this.classes[index-4] = 'right'
				}
			},
			before() {
				let last = this.classes.pop();
				this.classes.unshift(last);
			},
			after() {
				let first = this.classes.shift();
				this.classes.push(first);
			},
			change(e) {
				if (e.target.parentNode.classList.contains('left')) {
					this.after();
				} else if (e.target.parentNode.classList.contains('right')) {
					this.before()
				} else {
					return false;
				}
			},
			handleDownload() {
				axios.get("https://api.yuyouship.cn/offline/installpkg?ui=pc").then(
					(response) => {
						const {
							data
						} = response.data;
						if (!!data && data.length) {
							console.log("data[0].versionId", data[0].versionId);
							this.download(data[0].versionId);
						}
						console.log("data", data);
					},
					(error) => {
						console.log(error);
					}
				);
			},
			download(versionId) {
				console.log("versionId", versionId);
				axios.get("https://api.yuyouship.cn/offline/download?versionId=" + versionId).then(
					(response) => {
						const blob = new Blob([response.data]);
						this.saveAs(blob, decodeURIComponent(response.headers["download-filename"]));
					},
					(error) => {
						console.log(error);
					}
				);
			},
			saveAs(text, name, opts) {
				saveAs(text, name, opts);
			},
			changeHeight() {
				this.conentHeight = window.innerHeight - 96 - 26;
			},
		},
	};
</script>

<style lang="less" scoped>
	.carousel {
		width: 90%;
		height: 480px;
		margin: 0 5%;

		.whole {
			width: 45%;
			height: 100%;
			margin: 0 auto;

			.roll-img {
				width: 100%;
				height: 100%;
				position: relative;
				transform-style: preserve-3d;

				ul li {
					position: absolute;
					width: 100%;
					height: 100%;
					cursor: pointer;
					text-align: center;
					list-style: none;
				}
			}
		}

	}

	.acell {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	img {
		width: auto;
		height: 100%;
	}

	.left {
		left: -500px;
		transform: scale(0.8);
		z-index: 4;
		background: transparent;
		transition: all 0.5s ease;
	}

	.center {
		z-index: 6;
		left: 0;
		top: 0;
		bottom: 10%;
		transition: all 0.5s ease;
	}

	.right {
		left: 500px;
		transform: scale(0.8);
		z-index: 4;
		background: transparent;
		transition: all 0.5s ease;
	}

	.left div,
	.right div {
		z-index: 5;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		position: absolute;
		left: 0;
		top: 0;
		transition: all 0.3s ease;
	}

	.before1,
	.before2,
	.before3,
	.after1,
	.after2,
	.after3 {
		z-index: 3;
		left: 0;
		top: 0;
		visibility: hidden;
		transform: scale(0);
	}

	.last,
	.next {
		position: absolute;
		z-index: 10;
		width: 50px;
		height: 50px;
		// border: 5px solid rgba(255, 255, 255, 0.7);
		// border-radius: 50px;
		font-size: 50px;
		font-weight: bold;
		text-align: center;
		line-height: 45px;
		cursor: pointer;
		top: 45%;
		color: rgba(255, 255, 255, 0.6);
		text-shadow: 0px 0px 15px #999;
	}

	.list {
		width: 30%;
		height: 5px;
		display: flex;
		margin: 0 auto;
		margin-top: 20px;
	}

	.caption {
		position: absolute;
		width: 100%;
		text-align: center;
		bottom: 10px;
		font-size: 30px;
		font-weight: 500;
		text-shadow: 0px 8px 20px #000;
		color: #FFFFFF;
		display: none;

	}

	.show {
		display: block;
	}

	.btn {
		transition: all 0.3s ease;
		flex: 1;
		background: rgb(244, 244, 244);
		padding: 0;
	}

	.btn:not(:first-child) {
		margin-left: 20px;
	}

	.last {
		left: -350px;
	}

	.next {
		right: -350px;
	}

	// 指示灯
	.btn.active {
		background: #5936de;
	}
</style>
