<template>
  <section>
    <div class="top">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <div class="text-center mb-5">
            <h1 class="top-title">公司业绩</h1>
          </div>
        </div>
        <div class="text-center col-lg-12"></div>
      </div>
    </div>

    <div class="section container">
      <div class="row text-black mb-5">
        <h3>海上生活</h3>
      </div>
      <div class="row" style="gap: 20px">
        <div class="col-lg-4 row justify-content-center">
          <div class="image-card">
            <b-img class="image" :src="ship1"></b-img>
            <div class="image-title">山东渔业集团耕海一号（二期）海洋牧场</div>
          </div>
        </div>
        <div class="col-lg-4 row justify-content-center">
          <div class="image-card">
            <b-img class="image" :src="ship1"></b-img>
            <div class="image-title">外高桥 H1508 豪华邮轮（中国第一艘豪华邮轮项目）</div>
          </div>
        </div>

        <div class="col-lg-4 row justify-content-center">
          <div class="image-card">
            <b-img class="image" :src="ship1"></b-img>
            <div class="image-title">0181海监船</div>
          </div>
        </div>

        <div class="col-lg-4 row justify-content-center">
          <div class="image-card">
            <b-img class="image" :src="ship1"></b-img>
            <div class="image-title">80套海景智能舱室</div>
          </div>
        </div>
      </div>
    </div>

    <div class="section container">
      <div class="row text-black mb-5">
        <h3>智能船舶</h3>
      </div>
      <div class="row" style="gap: 20px">
        <div class="col-lg-4 row justify-content-center">
          <div class="image-card">
            <b-img class="image" :src="ship2"></b-img>
            <div class="image-title">招商局智能船舶运维平台 V-MINE</div>
          </div>
        </div>
        <div class="col-lg-4 row justify-content-center">
          <div class="image-card">
            <b-img class="image" :src="ship2"></b-img>
            <div class="image-title">中船Doss系统</div>
          </div>
        </div>
        <div class="col-lg-4 row justify-content-center">
          <div class="image-card">
            <b-img class="image" :src="ship2"></b-img>
            <div class="image-title">704船舶动力监控平台</div>
          </div>
        </div>
        <div class="col-lg-4 row justify-content-center">
          <div class="image-card">
            <b-img class="image" :src="ship2"></b-img>
            <div class="image-title">新瓯17</div>
          </div>
        </div>
        <div class="col-lg-4 row justify-content-center">
          <div class="image-card">
            <b-img class="image" :src="ship2"></b-img>
            <div class="image-title">新瓯 21</div>
          </div>
        </div>
        <div class="col-lg-4 row justify-content-center">
          <div class="image-card">
            <b-img class="image" :src="ship2"></b-img>
            <div class="image-title">东海无人艇测试平台管理系统</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ship1 from "@/assets/images/ship1.jpg";
import ship2 from "@/assets/images/ship2.jpg";

export default {
  name: "CopyRight",
  data() {
    return {
      ship1,
      ship2,
    };
  },
};
</script>

<style lang="less" scoped>
.top {
  background: #273a65;
  color: white;
  padding-top: 3rem;
}

.top-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-title::before {
  content: "";
  width: 100px;
  height: 1px;
  background-color: white;
  position: absolute;
  margin-left: -300px;
}
.top-title::after {
  content: "";
  width: 100px;
  height: 1px;
  background-color: white;
  position: absolute;
  margin-left: 300px;
}
.image-card {
  width: 30rem;
  height: 330px;
  background-color: rgba(128, 128, 128, 0.1);
  position: relative;
  padding: 0;
  margin: 0;
  border: 1px solid rgba(128, 128, 128, 0.2);
  display: flex;
  justify-content: center;
}
.image {
  height: 280px;
}
.image-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
</style>
