<template>
	<section>
		<div :style="{ height: conentHeight + 'px !important' }">
			<b-img :style="{ height: (conentHeight - 80) + 'px', width: '100%', position: 'absolute' }" :src="about">
			</b-img>
			<div class="about" style="height: 100%; width: 100%; position: relative">
				<div class="row justify-content-center">
					<div class="col-lg-9">
						<div class="text-center mb-5">
							<h1 class="about-title">公司简介</h1>
						</div>
					</div>
					<div class="col-lg-9">
						<h4>上海宇佑船舶科技有限公司位于美丽的滴水湖畔，上海自由贸易试验区临港新片区内。</h4>
						<br />
						<h4>公司成立以来一直在海洋船舶高科技领域深耕，陆续承接海洋船舶工程实施及研发项目共50多项，其中不乏行业内最顶级项目，是国内领先的船舶和海洋行业信息化解决方案服务商。公司专注数字化管理和应用软件产品的技术研发、技术推广及科研课题成果转化服务，不断追求产品技术先进性和应用服务的个性化，为客户提供船舶和海洋整体智慧解决方案。
						</h4>
						<br />
						<h4>在全球已进入资源、环境制约经济发展瓶颈期的今天，沿海国家和地区纷纷将竞争的视野转向海洋，并加快调整海洋战略，制定海洋开发政策，促进海洋经济的可持续发展。我国发展外向型经济，必须加强对海洋资源的开发，改变资源约束趋紧的局面。上海宇佑船舶科技有限公司至成立以来就致力于帮助涉海相关企业智能化管理以及数字化变革，以助力建设海洋强国为使命，创新引领，技术立身，产业报国。
						</h4>
					</div>
				</div>
			</div>
		</div>

		<div class="col-lg-12 row"
			style="padding: 1% 0;display: flex;flex-direction: row;align-items: center;justify-content: center;">
			<div class="row col-lg-5" style="justify-content: center;">
				<div class="col-lg-12">
					<div class="text-center mb-5">
						<h1 class="group-title">团队介绍</h1>
					</div>
					<div class="col-lg-12">
						<p>&emsp;&emsp;上海宇佑船舶科技有限公司汇聚了一批专业技术人员、研发人员和工程管理人员，公司依托总部资源将智慧城市、物联网及信息化等 ICT
							行业的先进理念引入到海洋及船舶相关领域。</p>
						<p>&emsp;&emsp;在依托上级公司信息化智能化研发、实施能力的同时，形成了一套完整、有效的质量保证体系，获有各船级社颁发的多种专业证书。</p>
						<p>&emsp;&emsp;公司内设立总经理室、总工程师室、市场项目部、研发产品部、技术 支持部、工程实施部、成套部、技术巡访部、信息技术中心、资财管理部及办公室等部门。</p>
						<p>&emsp;&emsp;目前，公司共有员工五十多人，高中级技术人员三十多名，大专以上学历员工占 90%。</p>
					</div>
				</div>
			</div>
			<div class="col-lg-5">
				<b-img
					style="height: 400px; width: 600px;display: flex;flex-direction: row;justify-content: flex-start;"
					:src="group"> </b-img>
			</div>
		</div>

		<!-- <div class="col-lg-12 row" style="padding: 5% 0">
      <div class="col-lg-5"><b-img style="height: 400px; width: 600px" :src="culture"> </b-img></div>
      <div class="row col-lg-6 justify-content-center">
        <div class="col-lg-6">
          <div class="text-center mb-5">
            <h1 class="group-title">企业文化</h1>
          </div>
          <div class="col-lg-12">
            <h2 style="color: #0068b6">思</h2>
            <p>集思广益，深思远虑。对事业，对科技，对行业</p>
            <h2 style="color: #0068b6">诺</h2>
            <p>一片诚意，一诺千金。对客户，对员工，对社会。</p>
            <h2 style="color: #0068b6">公司使命</h2>
            <p>帮助涉海相关企业智能化管理以及数字化变革，助力建设海洋强国。</p>
            <h2 style="color: #0068b6">公司愿景</h2>
            <p>致力成为国内乃至国际第一流海事信息化整体解决方案企业。</p>
          </div>
        </div>
      </div>
    </div> -->

		<div class="container col-lg-8">
			<div class="col-lg-12 row" style="padding: 5% 0">
				<div class="col-lg-5 mb-5"
					style="display: flex;flex-direction: row;align-items: center;justify-content: flex-start;margin-left: 140px;">
					<h1 class="group-title">企业文化</h1>
				</div>
				<div class="col-lg-12 row">
					<div class="col-lg-6">
						<b-img style="width: 400px" :src="think"> </b-img>
					</div>
					<div class="row col-lg-6">
						<div class="col-lg-12 row"
							style="height: 100%; display: flex; justify-content: center;flex-direction: column;">
							<div class="mt-5">
								<h1>思</h1>
							</div>
							<div class="col-lg-12">
								<p>集思广益，深思远虑。对事业，对科技，对行业</p>
							</div>
						</div>
					</div>
				</div>
				<div style="margin-bottom: 20px"></div>
				<div class="col-lg-12 row mb-5">
					<div class="row col-lg-6">
						<div class="col-lg-12 row"
							style="height: 100%; display: flex; justify-content: center;flex-direction: column;">
							<div class="mt-5">
								<h1>诺</h1>
							</div>
							<div class="col-lg-12">
								<p>一片诚意，一诺千金。对客户，对员工，对社会。</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<b-img style="width: 400px" :src="promise"> </b-img>
					</div>
				</div>
				<div style="margin-bottom: 20px"></div>
				<div class="col-lg-12 row mb-5">
					<div class="col-lg-6">
						<b-img style="width: 400px" :src="forward"> </b-img>
					</div>
					<div class="row col-lg-6">
						<div class="col-lg-12 row"
							style="height: 100%; display: flex; justify-content: center;flex-direction: column;">
							<div class="mt-5">
								<h1>公司使命</h1>
							</div>
							<div class="col-lg-12">
								<p>帮助涉海相关企业智能化管理以及数字化变革，助力建设海洋强国。</p>
							</div>
						</div>
					</div>
				</div>
				<div style="margin-bottom: 20px"></div>
				<div class="col-lg-12 row mb-5">
					<div class="row col-lg-6">
						<div class="col-lg-12 row"
							style="height: 100%; display: flex; justify-content: center;flex-direction: column;">
							<div class="mt-5">
								<h1>公司愿景</h1>
							</div>
							<div class="col-lg-12">
								<p>致力成为国内乃至国际第一流海事信息化整体解决方案企业。</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<b-img style="width: 400px" :src="culture"> </b-img>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import about from "@/assets/images/about.jpg";
	import group from "@/assets/images/group.png";
	import culture from "@/assets/images/culture.jpg";
	import think from "@/assets/images/think.png";
	import promise from "@/assets/images/promise.jpg";
	import forward from "@/assets/images/forward.png";
	export default {
		name: "CopyRight",
		data() {
			return {
				about,
				group,
				culture,
				think,
				promise,
				forward,
				conentHeight: 855
			};
		},
		mounted() {
			window.addEventListener("resize", this.changeHeight());
			this.changeHeight();
		},
		methods: {
			changeHeight() {
				this.conentHeight = window.innerHeight - 96 - 26;
				console.log(" this.conentHeight", this.conentHeight);
			},
		},
	};
</script>

<style lang="less" scoped>
	.aptitude {
		background: #273a65;
		color: white;
		padding-top: 3rem;
	}

	.about {
		padding-top: 80px;
		color: white;
	}

	.group-title,
	.about-title,
	.aptitude-title {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.group-title::before {
		content: "";
		width: 100px;
		height: 1px;
		background-color: #0068b6;
		position: absolute;
		margin-left: -300px;
	}

	.about-title::before,
	.aptitude-title::before {
		content: "";
		width: 100px;
		height: 1px;
		background-color: white;
		position: absolute;
		margin-left: -300px;
	}

	.about-title::after,
	.aptitude-title::after {
		content: "";
		width: 100px;
		height: 1px;
		background-color: white;
		position: absolute;
		margin-left: 300px;
	}

	.image-card {
		width: 30rem;
		height: 330px;
		background-color: rgba(128, 128, 128, 0.1);
		position: relative;
		padding: 0;
		margin: 0;
		border: 1px solid rgba(128, 128, 128, 0.2);
		display: flex;
		justify-content: center;
	}

	.image {
		height: 280px;
	}

	.image-title {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 50px;
		background: white;
		display: flex;
		justify-content: center;
		align-items: center;
		color: black;
	}

	.about1 {
		position: relative;
	}

	.about1-img {
		position: absolute;
	}
</style>
